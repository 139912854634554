import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Helmet>
      <body className="page-404" />
    </Helmet>
    <section
      className="banner-area"
      style={{
        backgroundImage:
          "url(" +
          "http://gbdevpress.enerbank.com/wp-content/uploads/2022/11/404-hero-temp.jpg" +
          ")",
      }}
    >
      <div className="row align-items-center relative banner-area-text-container">
        <div className="container text-center">
          <h1 className="text-white" style={{ padding: "80 0" }}>
            <div className="desktop">
              <span>404</span>
              <br></br>Oops! We can&#39;t find that page.{" "}
            </div>
            <div className="mobile">
              <span>404</span>
              <br></br>Oops!<br></br> We can&#39;t find that page.{" "}
            </div>
          </h1>
        </div>
      </div>
    </section>
    <section className="paragraph-text-block">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>
              You can try again or check out these popular destinations on our
              website below:
            </h2>
            <ul className="eb-green fa-ul">
              <li>
                <span className="fa-li">
                  <FontAwesomeIcon className="fa-icon" icon="credit-card" />
                </span>
                Are you trying to make a payment?{" "}
                <a href="https://enerbank.com/homeowner/" rel="noopener">
                  {" "}
                  Click here
                </a>
              </li>
              <li>
                <span className="fa-li">
                  <FontAwesomeIcon className="fa-icon" icon="layer-group" />
                </span>
                Would you like to learn more about our loan programs?{" "}
                <a
                  href="https://enerbank.com/contractor-financing/"
                  rel="noopener"
                >
                  {" "}
                  Click here
                </a>
              </li>
              <li>
                <span className="fa-li">
                  <FontAwesomeIcon className="fa-icon" icon="blog" />
                </span>
                Would you like to see our latest updates and helpful articles?
                Check out our blog{" "}
                <a href="https://enerbank.com/blog/" rel="noopener">
                  {" "}
                  here
                </a>
              </li>
              <li>
                <span className="fa-li">
                  <FontAwesomeIcon
                    className="fa-icon"
                    icon="phone"
                    flip="horizontal"
                  />
                </span>
                Would you like to contact us? Click{" "}
                <a href="https://enerbank.com/contact/" rel="noopener">
                  {" "}
                  Click here
                </a>
              </li>
              <li>
                <span className="fa-li">
                  <FontAwesomeIcon className="fa-icon" icon="house-chimney" />
                </span>
                Or visit our homepage{" "}
                <a href="https://enerbank.com/" rel="noopener">
                  {" "}
                  here
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
